export const environment = {
  production: true,
  AG_GRID_LICENSE_KEY: 'Evaluation_License_Valid_Until__27_October_2018__MTU0MDU5NDgwMDAwMA==d47615e03f843a16f65fc1bbf9c7cb53',
  COMMON_ERROR_MESSAGE: 'Sorry something went wrong. Please try again later. ',
  COMPANY_NAME: 'CustomBooks™',

  // --------Change based on environment-----------------------//
  LOGIN_URL: 'https://dev.custombooks.com',
  PARTNER_URL: 'https://signupdev.custombooks.com',
  PAY_URL: 'https://paydev.custombooks.com',
  INTCBK_URL: 'https://intcbkdev.custombooks.com',
  CAT_URL: 'https://catdev.custombooks.com',
  ADD_CARD_URL: 'https://addcarddev.custombooks.com',
  REGISTER_URL: 'https://registerdev.custombooks.com',
  ROCKSTAR_URL: 'https://rockstardev.custombooks.com',
  OAUTH_URL: 'https://oauthdev.custombooks.com',
  DEVELOPER_URL: 'https://apidocsdev.custombooks.com',
  MONITORING_URL: 'https://cbkmonitoringdev.custombooks.com',
  SYNC_ENGINE_TASK_RUNNER_URL: 'https://syncenginetaskrunnerdev.custombooks.com',

  CBK_LOGO_IMAGE: 'logo-dev.png',
  CBK_LOGO_LIGHT_IMAGE: 'logo-dev-light.png',
  GOOGLE_CAPTCHA_SITE_KEY: '6Lc7fB0UAAAAACCnGntjF7SD47HtX9Bk0O2QatGm',
  GOOGLE_MAP: {
    KEY: 'AIzaSyBQIC3GXwrh-gv0Ei-lSdgPtLT6nqoPqCI',
    CENTER_LAT: 41.70572869954652,
    CENTER_LNG: -99.40429725000001,
    ZOOM: 4
  },
  GOOGLE: {
    API_URL: 'https://accounts.google.com/o/oauth2/v2/auth',
    SCOPE: 'profile email'
  },
  LINKEDIN: {
    API_URL: 'https://www.linkedin.com/oauth/v2/authorization',
    SCOPE: 'openid,profile,email'
  },
  FACEBOOK: {
    API_URL: 'https://www.facebook.com/v4.0/dialog/oauth',
    SCOPE: 'email'
  },
  SOCIAL_LINK: {
    FACEBOOK: 'https://www.facebook.com/CustomBooksERP',
    TWITTER: 'https://x.com/custombookserp',
    LINKEDIN: 'https://www.linkedin.com/company/custombookserp',
    YOUTUBE: 'https://www.youtube.com/@CustomBooksERP',
  },
  disableConsoleLogging: false,
  CYRILLICPATTERN: /\p{Script=Cyrl}/u,
  // --------Change based on environment-----------------------//

  ProfessionalDesignationsOptions: [
    { value: '', control: 'accountant', name: 'Accountant' },
    { value: '', control: 'bookkeeper', name: 'Bookkeeper' },
    { value: '', control: 'cert_bookkeeper', name: 'CB (Certified Bookkeeper)' },
    { value: '', control: 'cfm', name: 'CFM' },
    { value: '', control: 'cma', name: 'CMA' },
    { value: '', control: 'computer_consultant', name: 'Computer Consultant' },
    { value: '', control: 'cpa', name: 'CPA' },
    { value: '', control: 'enrolled_agent', name: 'EA (Enrolled Agent)' },
    { value: '', control: 'educator_trainer', name: 'Educator/Trainer' },
    { value: '', control: 'vietnamese', name: 'Tax' },
    { value: '', control: 'tax_preparer', name: 'Preparer' },
  ],
  LanguageSpokenOptions: [
    { value: '', control: 'english', name: 'English' },
    { value: '', control: 'arabic', name: 'Arabic' },
    { value: '', control: 'cantonese', name: 'Cantonese' },
    { value: '', control: 'french', name: 'French' },
    { value: '', control: 'german', name: 'German' },
    { value: '', control: 'korean', name: 'Korean' },
    { value: '', control: 'mandarin', name: 'Mandarin' },
    { value: '', control: 'russian', name: 'Russian' },
    { value: '', control: 'spanish', name: 'Spanish' },
    { value: '', control: 'vietnamese', name: 'Vietnamese' },
    { value: '', control: 'other', name: 'Other' }
  ],
  IndustriesServedOptions: [
    { value: '', control: 'agriculture_farming', name: 'Agriculture/Farming' },
    { value: '', control: 'automotivesales_repair', name: 'Automotive Sales/Repair' },
    { value: '', control: 'cannabis', name: 'Cannabis' },
    { value: '', control: 'computer_software', name: 'Computer/Software' },
    { value: '', control: 'construction_contractors', name: 'Construction/Contractors' },
    { value: '', control: 'consulting', name: 'Consulting' },
    { value: '', control: 'design_architecture', name: 'Design/Architecture' },
    { value: '', control: 'financial_services', name: 'Financial Services' },
    { value: '', control: 'government_agency', name: 'Government Agency' },
    { value: '', control: 'hospitality', name: 'Hospitality' },
    { value: '', control: 'insurance_brokrage', name: 'Insurance/Brokerage' },
    { value: '', control: 'lawncare', name: 'Lawn Care/Landscaping' },
    { value: '', control: 'legal', name: 'Legal' },
    { value: '', control: 'medical_dental', name: 'Manufacturing' },
    { value: '', control: 'manufacturing', name: 'Medical/Dental/Health Services' },
    { value: '', control: 'non_profit', name: 'Non Profit' },
    { value: '', control: 'property_management', name: 'Property Management' },
    { value: '', control: 'real_estate', name: 'Real Estate/Developer' },
    { value: '', control: 'restaurant', name: 'Restaurant/Bar' },
    { value: '', control: 'retail', name: 'Retail' },
    { value: '', control: 'salon', name: 'Salon/Beauty' },
    { value: '', control: 'telecommunication', name: 'Telecommunications' },
    { value: '', control: 'transportation', name: 'Transportation' },
    { value: '', control: 'wholesale', name: 'Wholesale Distribution' },
  ],
  ServicesProvidedOptions: [
    { value: '', control: 'audit_review', name: 'Audits/reviews' },
    { value: '', control: 'fixed_audit_review', name: 'Fixed asset management' },
    { value: '', control: 'network_support', name: 'Network support' },
    { value: '', control: 'accountingsetup', name: 'CustomBooks consultation' },
    { value: '', control: 'accounting_consultant', name: 'Bookkeeping' },
    { value: '', control: 'bookkeeping', name: 'Payroll' },
    { value: '', control: 'payroll', name: 'Write-up' },
    { value: '', control: 'writeup', name: 'Financialplanning/investments' },
    { value: '', control: 'financialplanning_investsment', name: 'Income tax preparation/planning' },
    { value: '', control: 'incometax', name: 'CustomBooks setup' },
  ]
};
